<template>
  <div class='assistance-area'>
    <div  class='assistance-button'>
      Request Assistance
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, computed } from 'vue'

const emit = defineEmits(['showModal'])

emit('inFocus')
</script>
<style scoped>
.assistance-area {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.assistance-button {
    margin-top: 60px;
    margin-bottom: 60px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 138px;
height: 15px; */
  font-family: 'Helvetica Neue LT Std';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #b32d22;
  box-sizing: border-box;

  position: absolute;
  width: 209px;
  height: 60px;

  /* Alert */

  border: 3px solid #b32d22;
  border-radius: 30px;
}
</style>
