<template>
  <div class="status-item">
    <p :style="{fontSize: props.fontSize + 'px'}">{{statusItem.name}}</p>
    <img
      :src="disabled ? statusItem.iconDisabled ?? statusItem.icon : statusItem.icon"
      alt="status icon"
      :style="{height: props.imageSize + 'px'}"
    >
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import statuses from '@/data/statuses'

const props = defineProps({
  status: {
    type: String,
    required: true
  },
  fontSize: {
    type: String
  },
  imageSize: {
    type: String
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const statusItem = computed(() => statuses.find(status => status.value === props.status))
</script>

<style scoped>
.status-item {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
</style>
