import completed from '@/assets/icons/completed-status.svg'
import inReview from '@/assets/icons/in-review-status.svg'
import attentionRequired from '@/assets/icons/attention-required-status.svg'
import available from '@/assets/icons/available-status.svg'
import unavailable from '@/assets/icons/unavailable-status.svg'
import unavailableDisabled from '@/assets/icons/unavailable-disabled.svg'

export default [
 
  {
    icon: available,
    iconDisabled: null,
    value: 'available',
    name: 'To-Do'
  },
  {
    icon: attentionRequired,
    iconDisabled: null,
    value: 'attention-required',
    name: 'Assistance Requested'
  },

  {
    icon: inReview,
    iconDisabled: null,
    value: 'in-review',
    name: 'In Review'
  },
  {
    icon: completed,
    iconDisabled: null,
    value: 'completed',
    name: 'Complete'
  }
]
